 import type { OrganizationUserWorkEngagement } from '@pigello/pigello-matrix';

import { OrganizationUserWorkEngagementConfig } from "@pigello/pigello-matrix"



import { useGetInstance, useGetList } from '@/requests/hooks';
import type { useGetGenericInstanceProps, useGetGenericListProps, } from '@/requests/types';


export const useGetOrganizationUserWorkEngagement = (
  options: useGetGenericInstanceProps<OrganizationUserWorkEngagement>
) => {
  return useGetInstance<OrganizationUserWorkEngagement>({
    ...options,
    config: OrganizationUserWorkEngagementConfig,
    modelName: 'organizationuserworkengagement'
  });
};

export const useGetOrganizationUserWorkEngagementList = (
  options: useGetGenericListProps<OrganizationUserWorkEngagement>
) => {
  return useGetList<OrganizationUserWorkEngagement>({
    ...options,
    config: OrganizationUserWorkEngagementConfig,
    modelName: 'organizationuserworkengagement'
  });
};
