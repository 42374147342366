import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import { getPerms } from '@/lib/perms';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/16/solid';
import { useQueryClient } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'sonner';
import { systemEventsWidgets } from '../data/system-events';
import { useUpdateDashboard } from '../hooks/use-update-dashboard';
import type { DashBoard, Widget, WorkEngagementDashboard } from '../types';

const filteredWidgetsOnPermissions = (widgets: Widget[]) => {
  return widgets.filter(async (widget) => {
    const modelNames = widget.modelNames;
    if (!modelNames) return false;
    for await (const modelName of modelNames) {
      const perms = await getPerms(modelName);
      return perms.canView;
    }
  });
};

export function EditSystemWidgetsModal({
  dashboard,
  closeFunction,
}: {
  dashboard: DashBoard | WorkEngagementDashboard;
  closeFunction: VoidFunction;
}) {
  const filteredWidgets = useMemo(
    () => filteredWidgetsOnPermissions(systemEventsWidgets),
    []
  );
  const queryClient = useQueryClient();
  const { mutateAsync } = useUpdateDashboard();
  const [selected, setSelected] = useState<Map<string, Set<string>>>(new Map());
  const onSubmit = () => {
    const data = systemEventsWidgets
      .filter((widget) => (selected.get(widget.sub_category)?.size ?? 0) > 0)
      .map((widget) => ({
        ...widget,
        statistics: widget.statistics.filter((stat) => {
          return selected.get(widget.sub_category)?.has(stat.identifier);
        }),
      }));
    const clonedDashboard = cloneDeep(dashboard);
    const allOtherWidgets = clonedDashboard.widgets.filter(
      (widget) => widget.category !== 'system_events'
    );

    const newWidgets = [...allOtherWidgets, ...data];
    clonedDashboard.widgets = newWidgets;
    toast.promise(mutateAsync(clonedDashboard), {
      loading: 'Sparar...',
      success: () => {
        closeFunction();
        queryClient.invalidateQueries({
          queryKey: ['dashboard'],
        });
        return 'Sparad';
      },
      error: () => {
        return 'Misslyckades med att spara';
      },
    });
  };

  useEffect(() => {
    if (dashboard) {
      const newSelected = new Map();
      for (const widget of systemEventsWidgets) {
        const newSet = new Set();
        for (const stat of widget.statistics) {
          if (
            dashboard.widgets.find(
              (w) =>
                w.sub_category === widget.sub_category &&
                w.statistics.find((s) => s.identifier === stat.identifier)
            )
          ) {
            newSet.add(stat.identifier);
          }
        }
        newSelected.set(widget.sub_category, newSet);
      }
      setSelected(newSelected);
    }
  }, [dashboard]);
  return (
    <Dialog defaultOpen onOpenChange={closeFunction}>
      <DialogContent className='gap-0 p-0'>
        <DialogHeader>
          <DialogTitle>Redigera Systemhändelser</DialogTitle>
          <Button variant='secondary' size='icon-sm' onClick={closeFunction}>
            <span className='sr-only'>Stäng</span>
            <XMarkIcon className='size-4' />
          </Button>
          <DialogDescription className='sr-only'>
            Systemhändelser är systemhändelser som inte är relaterade till en
            specifik objekt. De kan till exempel vara något som händer i alla
            objekt eller något som händer i en viss objekttyp.
          </DialogDescription>
        </DialogHeader>
        <div className='h-96 overflow-y-auto p-4'>
          <Accordion type='multiple' className='grid gap-2'>
            {filteredWidgets.map((widget) => (
              <AccordionItem
                key={widget.sub_category}
                value={widget.sub_category}
                className='rounded-md border p-2  [&[data-state=open]>svg]:rotate-180'
              >
                <div className='flex items-center gap-2'>
                  <Checkbox
                    checked={
                      selected.get(widget.sub_category)?.size ===
                      widget.statistics.length
                    }
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setSelected((prev) => {
                          const newMap = new Map(prev);
                          newMap.set(
                            widget.sub_category,
                            new Set(
                              widget.statistics.map((stat) => stat.identifier)
                            )
                          );
                          return newMap;
                        });
                      } else {
                        setSelected((prev) => {
                          const newMap = new Map(prev);
                          newMap.set(widget.sub_category, new Set());
                          return newMap;
                        });
                      }
                    }}
                  />
                  <AccordionTrigger
                    icon={false}
                    className='flex items-center justify-between gap-2 py-0 hover:no-underline'
                  >
                    <div className='flex flex-col items-start'>
                      <span> {widget.name.split('-')[1]}</span>
                      <span className='text-xs font-normal text-muted-foreground'>
                        {selected.get(widget.sub_category)?.size}/
                        {widget.statistics.length}
                      </span>
                    </div>
                    <ChevronDownIcon className='size-4 transition-transform duration-200' />
                  </AccordionTrigger>
                </div>
                <AccordionContent>
                  <div className='grid gap-2 p-2'>
                    {widget.statistics.map((stat) => (
                      <div
                        key={stat.identifier}
                        className='flex items-center gap-2 pl-2'
                      >
                        <Checkbox
                          id={stat.identifier}
                          checked={selected
                            .get(widget.sub_category)
                            ?.has(stat.identifier)}
                          onCheckedChange={(checked) => {
                            if (checked) {
                              setSelected((prev) => {
                                const newMap = new Map(prev);
                                newMap.set(
                                  widget.sub_category,
                                  new Set(
                                    prev
                                      .get(widget.sub_category)
                                      ?.add(stat.identifier)
                                  )
                                );
                                return newMap;
                              });
                            } else {
                              setSelected((prev) => {
                                prev
                                  .get(widget.sub_category)
                                  ?.delete(stat.identifier);
                                return new Map(prev);
                              });
                            }
                          }}
                        />
                        <Label htmlFor={stat.identifier}>{stat.name}</Label>
                      </div>
                    ))}
                  </div>
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
        <DialogFooter>
          <Button onClick={onSubmit} type='submit'>
            Spara
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
