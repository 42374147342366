import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { XMarkIcon } from '@heroicons/react/16/solid';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'sonner';
import { DASHBOARD_QUERY_KEY } from '../hooks/use-get-dashboard';
import { useUpdateDashboard } from '../hooks/use-update-dashboard';
import type { DashBoard, WorkEngagementDashboard } from '../types';

export function UpdateDashboardModal({
  closeFunction,
  currentDashboard,
}: {
  closeFunction: () => void;
  currentDashboard: DashBoard | WorkEngagementDashboard | undefined;
}) {
  const [dashboardNameUpdate, setDashboardNameUpdate] = useState('');
  const { mutateAsync: mutateDashboard, isPending: isUpdatingDashboard } =
    useUpdateDashboard();
  const queryClient = useQueryClient();
  const clearAndClose = () => {
    setDashboardNameUpdate('');
    closeFunction();
  };
  const updateDashboard = () => {
    if (!currentDashboard) return;
    currentDashboard.name = dashboardNameUpdate;
    toast.promise(mutateDashboard(currentDashboard), {
      loading: 'Sparar...',
      success: () => {
        queryClient.invalidateQueries({
          queryKey: [DASHBOARD_QUERY_KEY],
        });
        clearAndClose();
        return 'Dashboard sparades';
      },
      error: 'Misslyckades med att spara dashboard',
    });
  };

  return (
    <Dialog defaultOpen onOpenChange={clearAndClose}>
      <DialogContent size='sm' className='p-0'>
        <DialogHeader className='border-b-0'>
          <DialogTitle>Redigera {currentDashboard?.name}</DialogTitle>
          <Button variant='secondary' onClick={clearAndClose} size={'icon-sm'}>
            <XMarkIcon className='size-4 shrink-0' />
          </Button>
          <DialogDescription className='sr-only'>
            Redigera {currentDashboard?.name}
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className='grid gap-2 p-4'>
            <Label htmlFor='dashboard-name'>Namn på dashboard</Label>
            <Input
              id='dashboard-name'
              placeholder='Namn på dashboard'
              defaultValue={currentDashboard?.name}
              onChange={(e) => setDashboardNameUpdate(e.target.value)}
            />
          </div>
          <DialogFooter className='border-t-0'>
            <Button
              disabled={
                isUpdatingDashboard || dashboardNameUpdate.trim().length === 0
              }
              type='submit'
              onClick={updateDashboard}
            >
              Spara
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
