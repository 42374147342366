import type {
  BaseInstance,
  IBaseInstanceConfig,
  ModelName,
} from '@pigello/pigello-matrix';
import * as api from './api/get';
import { getList } from './api/get-list';
import type { getGenericInstanceProps, getGenericListProps } from './types';

export const fetchGenericInstance = async <Instance extends BaseInstance>(
  options: getGenericInstanceProps<Instance> & {
    modelName?: ModelName;
    config?: IBaseInstanceConfig<Instance>;
  }
) => {
  if (!options.modelName && !options.config)
    throw new Error('Please supply either a model name or a config');

  const res = await api.get(options);

  return res;
};

export const fetchGenericList = async <Instance extends BaseInstance>(
  options: getGenericListProps<Instance> & {
    modelName?: ModelName;
    config?: IBaseInstanceConfig<Instance>;
  }
) => {
  if (!options.modelName && !options.config)
    throw new Error('Please supply either a model name or a config');

  const queryParams = {
    ...options.queryParams,
    page: options.queryParams.page,
    pageSize: options.queryParams.pageSize,
    search: options.queryParams.search,
  };

  const res = await getList({
    ...options,
    queryParams,
  });

  return res.data;
};
