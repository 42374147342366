import { fetchApi } from '@/requests/api';
import { BASE_DASHBOARD_URL } from '@/requests/constants';
import { useMutation, type UseMutationOptions } from '@tanstack/react-query';

export function useDeleteDashboard(
  options?: UseMutationOptions<void, Error, { key: string }>
) {
  return useMutation({
    ...options,
    mutationFn: async (body) => {
      const res = await fetchApi({
        url: `${BASE_DASHBOARD_URL}/myworkday/dashboard/user`,
        method: 'DELETE',
        body: JSON.stringify(body),
      });
      if (!res.ok) throw new Error('Failed to update dashboard');
    },
  });
}
