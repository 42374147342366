'use client';

import {
  getDateRange,
  type DateCategory,
  type DateRange,
} from '@/app/api/utils';
import { badgeVariants } from '@/components/ui/badge';
import { Button, buttonVariants } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Loader } from '@/components/ui/loader';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Skeleton } from '@/components/ui/skeleton';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { getTabIcon } from '@/components/window-keeper/tab-icon';
import { cn } from '@/lib/cn';
import { getPerms } from '@/lib/perms';
import { ObjectKeys } from '@/lib/utils';
import {
  ArrowRightEndOnRectangleIcon,
  DocumentTextIcon,
  NoSymbolIcon,
  PencilIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid';
import { useQueryClient } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { cloneDeep } from 'lodash';
import Link from 'next/link';
import { useMemo, useState } from 'react';
import { toast } from 'sonner';
import { systemEventsWidgets } from '../data/system-events';
import { useGetStatistics } from '../hooks/use-get-statistics';
import { useUpdateDashboard } from '../hooks/use-update-dashboard';
import type {
  DashBoard,
  Statistic,
  Widget,
  WorkEngagementDashboard,
} from '../types';
import {
  createUrl,
  extractIdentifiers,
  processTimeline,
  specialFuckingCases,
} from '../utils';
import { EditSystemWidgetsModal } from './edit-system-widgets';

const filteredWidgetsOnPermissions = (
  widgets: Omit<Widget, 'modelNames'>[]
) => {
  return widgets.filter(async (widget) => {
    const modelNames = systemEventsWidgets.find(
      (d) => d.sub_category === widget.sub_category
    )?.modelNames;
    if (!modelNames) return false;
    for await (const modelName of modelNames) {
      const perms = await getPerms(modelName);
      return perms.canView;
    }
  });
};
const selectedRangeTranslationMap: Record<string, string>[] = [
  {
    title: 'dygnet',
    value: 'day',
  },
  {
    title: 'veckan',
    value: 'week',
  },
  {
    title: 'månaden',
    value: 'month',
  },
  {
    title: 'året',
    value: 'year',
  },
];

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.07,
    },
  },
};
const item = {
  hidden: { opacity: 0, y: -5 },
  show: { opacity: 1, y: 0 },
};
const rangeTranslationMap: Record<string, string> = {
  pending: 'Kommande',
  done: 'Innevarande',
};

export function SystemEvents({
  dashboards,
  dashboardKey,
  isWorkEngagementDashboard,
}: {
  dashboardKey: string | undefined;
  dashboards: (DashBoard | WorkEngagementDashboard)[] | undefined;
  isWorkEngagementDashboard: boolean;
}) {
  const dashboard = dashboards?.find((d) => d.key === dashboardKey);
  const [editOpen, setEditOpen] = useState(false);
  const unFilteredSystemEventsWidgets = dashboard?.widgets.filter(
    (widget) => widget.category === 'system_events'
  );
  const systemEventsWidgets = useMemo(() => {
    if (!unFilteredSystemEventsWidgets) return [];
    return filteredWidgetsOnPermissions(unFilteredSystemEventsWidgets);
  }, [unFilteredSystemEventsWidgets]);

  const [range, setRange] = useState<string>('day');
  const [dateCategory, setDateCategory] = useState<string>('done');
  const groupByCategory = Object.groupBy(
    systemEventsWidgets ?? [],
    (item) => item.category
  );
  const groupBySubCategory = Object.groupBy(
    systemEventsWidgets ?? [],
    (item) => item.sub_category
  );
  const identifiers = useMemo(
    () => extractIdentifiers(groupByCategory),
    [groupByCategory]
  );

  const { data, isLoading, isFetching, isError } = useGetStatistics({
    options: {
      identifiers,
      widget: 'system-events',
      range,
      dateCategory,
    },
    enabled: !!dashboard?.key,
  });
  const groupDataByIdentifier = Object.groupBy(
    data ?? [],
    (item) => item.identifier ?? 'none.none.none'
  );

  const pinnedData =
    systemEventsWidgets
      ?.map((widget) => widget.statistics.filter((stat) => stat.pinned))
      .flatMap((widget) => widget) ?? [];

  const queryClient = useQueryClient();

  const { mutateAsync: updateDashboard } = useUpdateDashboard();
  const onSave = (stat: Statistic) => {
    if (!dashboard) return;
    const dashboardClone = cloneDeep(dashboard);
    const updatedWidget = dashboardClone.widgets.map((widget) => {
      const updatedStatistic = widget.statistics.map((s) => {
        if (s.identifier === stat.identifier) {
          return {
            ...s,
            pinned: !stat.pinned,
          };
        }
        return s;
      });
      return {
        ...widget,
        statistics: updatedStatistic,
      };
    });
    dashboardClone.widgets = updatedWidget;
    toast.promise(updateDashboard(dashboardClone), {
      loading: 'Sparar...',
      success: () => {
        queryClient.invalidateQueries({
          queryKey: ['dashboard'],
        });
        return 'Uppdaterades';
      },
      error: () => {
        return 'Misslyckades med att spara';
      },
    });
  };

  return (
    <>
      {editOpen && dashboard && (
        <EditSystemWidgetsModal
          closeFunction={() => setEditOpen(false)}
          dashboard={dashboard}
        />
      )}
      <Card className='gap-0 p-0'>
        <CardHeader className='flex flex-col gap-2 p-4'>
          <div className='flex items-center justify-between'>
            <CardTitle>Systemhändelser</CardTitle>
            {!isWorkEngagementDashboard && dashboardKey && (
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant={'outline'}
                    size={'icon'}
                    onClick={() => setEditOpen(true)}
                  >
                    <PencilIcon className='size-4' />
                  </Button>
                </TooltipTrigger>
                <TooltipContent className='font-normal'>
                  Redigera
                </TooltipContent>
              </Tooltip>
            )}
          </div>
          <div className='flex items-center justify-between'>
            <Select value={range} onValueChange={setRange}>
              <SelectTrigger className='max-w-72 items-start [&_[data-description]]:hidden'>
                <SelectValue placeholder='Välj en systemhändelse' />
              </SelectTrigger>
              <SelectContent>
                {selectedRangeTranslationMap.map((rangeTranslation) => (
                  <SelectItem
                    key={rangeTranslation.value}
                    value={rangeTranslation.value}
                  >
                    <div className='flex items-start gap-3 text-muted-foreground'>
                      <div className='grid gap-0.5'>
                        <p>
                          {rangeTranslationMap[dateCategory]}{' '}
                          <span className='font-medium text-foreground'>
                            {rangeTranslation.title}
                          </span>
                        </p>
                        <p className='text-xs' data-description>
                          {getDateRange(
                            rangeTranslation.value as DateRange,
                            dateCategory as DateCategory
                          )?.startDate.toLocaleString()}{' '}
                          -{' '}
                          {getDateRange(
                            rangeTranslation.value as DateRange,
                            dateCategory as DateCategory
                          )?.endDate.toLocaleString()}
                        </p>
                      </div>
                    </div>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Tabs value={dateCategory} onValueChange={setDateCategory}>
              <TabsList>
                <TabsTrigger value='done'>Utförda</TabsTrigger>
                <TabsTrigger value='pending'>Kommande</TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
        </CardHeader>
        <CardContent className='relative flex h-64 w-full flex-col'>
          {isFetching && !isLoading && (
            <div className='absolute inset-0 z-50 my-0 flex h-full items-center justify-center bg-background/80'>
              <Loader message='Hämtar systemhändelser...' className='my-0' />
            </div>
          )}
          {!isLoading && !isFetching && !data?.length && !isError && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className='flex h-full flex-col items-center justify-center gap-0.5 text-center'
            >
              <DocumentTextIcon className='size-6' />
              <span className='text-sm font-medium'>Systemhändelser</span>
              <span className='text-xs'>
                {!dashboardKey
                  ? 'Välj eller skapa en dashboard för att se systemhändelser'
                  : 'Inga systemhändelser.'}
              </span>
            </motion.div>
          )}
          {isLoading &&
            Array({ length: identifiers.length }).map(() => (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                key={crypto.randomUUID()}
                className={
                  'flex w-full items-center justify-between border-b px-4 py-2 last:border-b-0'
                }
              >
                <div className='flex items-center gap-2'>
                  <Skeleton className='size-8 rounded-full border p-2' />
                  <Skeleton className='h-3 w-20' />
                </div>
                <Skeleton className='size-3' />
              </motion.div>
            ))}
          {!isLoading && isError && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className='flex h-full flex-col items-center justify-center gap-0.5 text-center'
            >
              <NoSymbolIcon className='size-6' />
              <span className='text-sm font-medium'>Något gick fel</span>
              <span className='text-xs'>
                Försök igen, kontakta Pigello om problemet kvarstår
              </span>
            </motion.div>
          )}
          <div className='flex-1 overflow-auto'>
            {pinnedData.map((stat) => {
              const identifier = stat.identifier;

              if (identifier === 'none.none.none') {
                return null;
              }
              const totalCount = data
                ?.filter((item) => item.identifier === identifier)
                .reduce((acc, cur) => acc + cur.count, 0);
              const systemEventData = data?.find(
                (d) => d.identifier === identifier
              );
              const debtTimeLine = systemEventData?.timeline ?? {};
              const timeline = Object.keys(systemEventData?.timeline ?? {});
              if (!stat || !systemEventData) return null;
              const sendoutCount = processTimeline(
                timeline,
                range as DateRange,
                dateCategory as DateCategory,
                debtTimeLine,
                stat
              );
              return (
                <motion.div
                  key={stat.identifier}
                  variants={item}
                  className='group flex w-full items-center justify-between border-b bg-secondary px-4 py-2 last:border-b-0 hover:bg-muted'
                >
                  <div className='flex items-center gap-2'>
                    {systemEventData.modelName ? (
                      getTabIcon(
                        systemEventData.modelName,
                        cn(
                          badgeVariants({
                            variant: 'outline',
                          }),
                          'size-8 rounded-full border p-2'
                        )
                      )
                    ) : (
                      <NoSymbolIcon
                        className={cn(
                          badgeVariants({
                            variant: 'outline',
                          }),
                          'size-8 shrink-0 rounded-full border p-2'
                        )}
                      />
                    )}
                    <div className='text-sm font-medium'>{stat.name}</div>
                  </div>
                  <div className='flex items-center gap-2'>
                    <div className='pointer-events-none flex items-center opacity-0 transition-opacity group-hover:pointer-events-auto group-hover:opacity-100'>
                      {Object.keys(systemEventData.filters ?? {}).length >
                        0 && (
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Link
                              prefetch={false}
                              className={buttonVariants({
                                variant: 'ghost',
                                size: 'icon',
                              })}
                              href={createUrl(
                                systemEventData.modelName,
                                systemEventData.filters
                              )}
                            >
                              <ArrowRightEndOnRectangleIcon className='size-4 scale-105 transition-transform' />
                            </Link>
                          </TooltipTrigger>
                          <TooltipContent>Gå till {stat.name}</TooltipContent>
                        </Tooltip>
                      )}
                      {!isWorkEngagementDashboard && (
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant='ghost'
                              size='icon'
                              onClick={() => onSave(stat)}
                            >
                              <XMarkIcon className='size-4' />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>Ta bort</TooltipContent>
                        </Tooltip>
                      )}
                    </div>
                    <div
                      className={cn(
                        'hidden font-medium',
                        (sendoutCount.has(stat.identifier) ||
                          specialFuckingCases[stat.identifier]) &&
                          'block'
                      )}
                    >
                      {sendoutCount.get(stat.identifier) ?? 0}
                    </div>
                    <div
                      className={cn(
                        'hidden font-medium',
                        !sendoutCount.has(stat.identifier) &&
                          !specialFuckingCases[stat.identifier] &&
                          'block'
                      )}
                    >
                      {totalCount}
                    </div>
                  </div>
                </motion.div>
              );
            })}
            {!isLoading && !!data?.length && (
              <motion.div variants={container} initial='hidden' animate='show'>
                {ObjectKeys(groupDataByIdentifier ?? {})?.map((identifier) => {
                  if (identifier === 'none.none.none') {
                    return null;
                  }
                  const subCategory = identifier.split('.')[1];
                  const statistics = groupBySubCategory[subCategory]?.flatMap(
                    (item) => item.statistics
                  );
                  const modelName = groupDataByIdentifier[identifier]?.find(
                    (item) => item.identifier === identifier
                  )?.modelName;

                  const totalCount = data
                    .filter((item) => item.identifier === identifier)
                    .reduce((acc, cur) => acc + cur.count, 0);

                  const stat = statistics?.find(
                    (item) => item.identifier === identifier
                  );
                  if (stat?.pinned) {
                    return null;
                  }
                  const systemEventData = data.find(
                    (d) => d.identifier === identifier
                  );
                  const debtTimeLine = systemEventData?.timeline ?? {};
                  const timeline = Object.keys(systemEventData?.timeline ?? {});
                  if (!stat || !systemEventData) return null;

                  const sendoutCount = processTimeline(
                    timeline,
                    range as DateRange,
                    dateCategory as DateCategory,
                    debtTimeLine,
                    stat
                  );

                  return (
                    <motion.div
                      key={stat.identifier}
                      variants={item}
                      className='group flex w-full items-center justify-between border-b px-4 py-2 last:border-b-0 hover:bg-muted'
                    >
                      <div className='flex items-center gap-2'>
                        {modelName ? (
                          getTabIcon(
                            modelName,
                            cn(
                              badgeVariants({
                                variant: 'outline',
                              }),
                              'size-8 rounded-full border p-2'
                            )
                          )
                        ) : (
                          <NoSymbolIcon
                            className={cn(
                              badgeVariants({
                                variant: 'outline',
                              }),
                              'size-8 shrink-0 rounded-full border p-2'
                            )}
                          />
                        )}
                        <div className='text-sm font-medium'>{stat.name}</div>
                      </div>
                      <div className='flex items-center gap-2'>
                        <div className='pointer-events-none flex items-center opacity-0 transition-opacity group-hover:pointer-events-auto group-hover:opacity-100'>
                          {Object.keys(systemEventData.filters ?? {}).length >
                            0 && (
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Link
                                  prefetch={false}
                                  className={buttonVariants({
                                    variant: 'ghost',
                                    size: 'icon',
                                  })}
                                  href={createUrl(
                                    systemEventData.modelName,
                                    systemEventData.filters
                                  )}
                                >
                                  <ArrowRightEndOnRectangleIcon className='size-4 scale-105 transition-transform' />
                                </Link>
                              </TooltipTrigger>
                              <TooltipContent>
                                Gå till {stat.name}
                              </TooltipContent>
                            </Tooltip>
                          )}
                          {!isWorkEngagementDashboard && (
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Button
                                  variant='ghost'
                                  size='icon'
                                  onClick={() => onSave(stat)}
                                >
                                  <PlusIcon className='size-4' />
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent>Pinna till toppen</TooltipContent>
                            </Tooltip>
                          )}
                        </div>
                        <div
                          className={cn(
                            'hidden font-medium',
                            (sendoutCount.has(stat.identifier) ||
                              specialFuckingCases[stat.identifier]) &&
                              'block'
                          )}
                        >
                          {sendoutCount.get(stat.identifier) ?? 0}
                        </div>
                        <div
                          className={cn(
                            'hidden font-medium',
                            !sendoutCount.has(stat.identifier) &&
                              !specialFuckingCases[stat.identifier] &&
                              'block'
                          )}
                        >
                          {totalCount}
                        </div>
                      </div>
                    </motion.div>
                  );
                })}
              </motion.div>
            )}
          </div>
        </CardContent>
      </Card>
    </>
  );
}
