import type { ParsedCookies } from '@/requests/cookies';
import { getCookies } from '@/requests/cookies';
import { useEffect, useState } from 'react';

export function useGetCookies() {
  const [cookies, setCookies] = useState<ParsedCookies | null>(null);
  useEffect(() => {
    const cookies = async () => {
      const { cookies } = await getCookies();
      setCookies(cookies);
    };
    cookies();
  }, []);
  return { cookies };
}
