'use client';

import PageHeader from '@/app/settings/components/PageHeader';
import { useGetSelf } from '@/hooks/useGetSelf';

import OverlayLoader from '@/components/ui/overlay-loader';
import { useGetOrganizationUserList } from '@/config/accounts/users/organization/organizationuser/client';
import { useGetOrganizationUserWorkEngagementList } from '@/config/accounts/users/organization/organizationuserworkengagement/client';
import { cn } from '@/lib/cn';
import { CalendarDaysIcon } from '@heroicons/react/16/solid';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useSessionStorage } from 'usehooks-ts';
import { DashboardNotes } from './components/dashboard-notes';
import { DashboardNotifications } from './components/dashboard-notifications';
import { HandleDashboardModal } from './components/handle-dashboard-modal';
import { KeyIndexes } from './components/key-indexes';
import { SystemEvents } from './components/system-events';
import { ToHandleWidget } from './components/to-handle-widget';
import { useGetDashboards } from './hooks/use-get-dashboard';
import { useGetDashboardWorkEngagement } from './hooks/use-get-dashboard-external-client';
import { getGreeting } from './utils';
export default function DashboardPage() {
  const { data: self, isLoading: isLoadingSelf } = useGetSelf();
  const { data: workEngagements, isLoading: isLoadingWorkEngagements } =
    useGetOrganizationUserWorkEngagementList({
      queryParams: {
        page: 1,
        pageSize: 100,
        filters: {
          ...(self?.id && {
            user: {
              noop: self?.id,
            },
          }),
        },
      },

      enabled: !!self?.id,
      nested: ['workEngagement'],
    });
  const workEngagementIds = [
    ...new Set(
      (workEngagements?.list ?? [])
        .map((w) => w.workEngagement?.id)
        .filter((id) => id)
    ),
  ] as string[];

  const [dashboardKey, setDashboardKey] = useSessionStorage<string | undefined>(
    'dashboardKey',
    undefined
  );

  const {
    data: dashboards,
    isPending: isLoadingDashboards,
    isRefetching: isLoadingDashboardsRefetching,
  } = useGetDashboards({
    enabled: !!self?.id,
  });

  const {
    data: workEngagementsDashboards,
    isLoading: isLoadingWorkEngagementsDashboards,
  } = useGetDashboardWorkEngagement({
    workEngagementIds: workEngagementIds,
    enabled: !!workEngagementIds?.length,
  });

  const isLoading =
    isLoadingDashboards ||
    isLoadingWorkEngagementsDashboards ||
    isLoadingWorkEngagements;

  const allDashboards = useMemo(
    () => [
      ...(Array.isArray(dashboards) ? dashboards : []),
      ...(Array.isArray(workEngagementsDashboards)
        ? workEngagementsDashboards
        : []),
    ],
    [dashboards, workEngagementsDashboards]
  );

  const currentDashboard = allDashboards?.find(
    (dashboard) => dashboardKey === dashboard.key
  );

  const favoriteDashboard = dashboards?.find(
    (dashboard) => dashboard.ranking === 0
  );
  const isWorkEngagementDashboard =
    !!currentDashboard && 'work_engagement_id' in currentDashboard;

  const createdByOrganizationUserIds = (workEngagementsDashboards ?? [])?.map(
    (we) => we.created_by_user_id
  );

  const { data: createdByOrganizationUsers } = useGetOrganizationUserList({
    queryParams: {
      filters: {
        id: {
          __in: createdByOrganizationUserIds?.join(','),
        },
      },
      page: 1,
      pageSize: 50,
    },
    enabled: !!createdByOrganizationUserIds?.length,
  });

  const firstDashboardKey = dashboards?.[0]?.key;
  useEffect(() => {
    if (!isLoadingDashboards && !isLoadingDashboardsRefetching) {
      if (dashboardKey) return;

      if (favoriteDashboard) {
        setDashboardKey(favoriteDashboard?.key);
      } else if (firstDashboardKey) {
        setDashboardKey(firstDashboardKey);
      }
    }
  }, [
    dashboardKey,
    favoriteDashboard,
    firstDashboardKey,
    isLoadingDashboards,
    isLoadingDashboardsRefetching,
    setDashboardKey,
  ]);

  return (
    <>
      <div className='p-6'>
        <PageHeader className='flex flex-col items-start'>
          <div className='flex w-full items-center justify-between'>
            <h2>
              {getGreeting()} {self?.communicationName} 👋
            </h2>
            <HandleDashboardModal
              dashboardKey={dashboardKey}
              setDashboardKey={setDashboardKey}
              dashboards={dashboards}
              workEngagementsDashboards={workEngagementsDashboards}
              isLoadingDashboards={isLoading}
              isWorkEngagementDashboard={isWorkEngagementDashboard}
              organizationUserWorkEngagements={workEngagements?.list}
              createdByOrganizationUsers={createdByOrganizationUsers?.list}
            />
          </div>
          <div className='flex items-center gap-2 text-sm font-medium text-muted-foreground'>
            <CalendarDaysIcon className='size-6' />
            <span>{DateTime.now().toFormat('cccc d LLL HH:mm')}</span>
          </div>
        </PageHeader>
        <div className='relative flex size-full flex-col'>
          {(isLoadingDashboards || isLoadingSelf) && (
            <OverlayLoader message='Hämtar dashboards...' />
          )}
          {!isLoadingSelf && (
            <div
              className={cn(
                'grid grid-cols-8 gap-8 lg:grid-cols-7',
                isWorkEngagementDashboard && 'grid-cols-6 lg:grid-cols-6'
              )}
            >
              <div className='col-span-full'>
                <KeyIndexes
                  dashboards={allDashboards}
                  dashboardKey={dashboardKey}
                  isWorkEngagementDashboard={isWorkEngagementDashboard}
                />
              </div>
              <div
                className={cn(
                  'col-span-full lg:col-span-5',
                  isWorkEngagementDashboard && 'col-span-full lg:col-span-full'
                )}
              >
                <ToHandleWidget
                  dashboards={allDashboards}
                  dashboardKey={dashboardKey}
                  isWorkEngagementDashboard={isWorkEngagementDashboard}
                />
              </div>
              {/* <div
                className={cn(
                  'col-span-4 lg:col-span-2',
                  isWorkEngagementDashboard && 'col-span-3 lg:col-span-3'
                )}
              >
                <DashboardBookmarks />
              </div> */}
              {!isWorkEngagementDashboard && (
                <div className='col-span-4 lg:col-span-2'>
                  <DashboardNotes
                    dashboards={allDashboards}
                    dashboardKey={dashboardKey}
                    isLoadingDashboards={isLoadingDashboards}
                  />
                </div>
              )}
              <div
                className={cn(
                  'col-span-full lg:col-span-5',
                  isWorkEngagementDashboard && 'col-span-full lg:col-span-4'
                )}
              >
                <SystemEvents
                  dashboards={allDashboards}
                  dashboardKey={dashboardKey}
                  isWorkEngagementDashboard={isWorkEngagementDashboard}
                />
              </div>
              <div
                className={cn(
                  'col-span-full lg:col-span-2',
                  isWorkEngagementDashboard && 'col-span-full lg:col-span-2'
                )}
              >
                <DashboardNotifications
                  dashboards={allDashboards}
                  dashboardKey={dashboardKey}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
