import { fetchApi } from '@/requests/api';
import { BASE_DASHBOARD_URL } from '@/requests/constants';
import { useMutation } from '@tanstack/react-query';
import type { DashBoard } from '../types';

export function useCreateDashboard() {
  return useMutation<DashBoard, Error, Partial<DashBoard>, unknown>({
    mutationFn: async (dashboard) => {
      const res = await fetchApi({
        url: `${BASE_DASHBOARD_URL}/myworkday/dashboard/user`,
        method: 'POST',
        body: JSON.stringify(dashboard),
      });
      if (!res.ok) throw new Error('Failed to create dashboard');
      const data = await res.json();
      return data;
    },
  });
}
