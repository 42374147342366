import type { JsonFilter } from '@/hooks/use-table-filter/types';
import { fetchApi } from '@/requests/api';
import type { ModelName } from '@pigello/pigello-matrix';
import {
  keepPreviousData,
  useQuery,
  type UseQueryOptions,
} from '@tanstack/react-query';
import qs from 'query-string';

const qsOptions: qs.StringifyOptions = {
  arrayFormat: 'comma',
  skipNull: true,
  skipEmptyString: true,
};

type WidgetStatistics = {
  identifier: `${string}.${string}.${string}`;
  filters: JsonFilter;
  modelName: ModelName;
  count: number;
  timeline?: { [date: string]: { [debtKind: number]: number } };
};

type StatisticsProps = {
  identifiers: string[];
  widget: 'key-indexes' | 'system-events' | 'to-handle';
  range?: string;
  dateCategory?: string;
  dashboardKey?: string;
};

type Props = Omit<UseQueryOptions<WidgetStatistics[], unknown>, 'queryKey'> & {
  options: StatisticsProps;
};

export function useGetStatistics(options: Props) {
  const { identifiers, widget, range, dateCategory, dashboardKey } =
    options.options;
  return useQuery({
    ...options,
    queryKey: [widget, identifiers, range, dateCategory, dashboardKey],
    queryFn: async () => {
      const params = qs.stringify(
        {
          identifiers,
          range,
          dateCategory,
        },
        qsOptions
      );
      const res = await fetchApi({
        url: `/api/statistics/${widget}?${params}`,
        next: {
          revalidate: 0,
        },
      });

      if (!res.ok) throw new Error('Failed to fetch statistics');
      const data = await res.json();
      return data;
    },
    staleTime: 60 * 5 * 1000,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: true,
  });
}
