'use client';

import type { QueryParams } from '@/requests/api/types';
import { fetchGenericList } from '@/requests/fetching';
import type { ListResponse, Meta } from '@/requests/types';
import type { BaseInstance, ModelName } from '@pigello/pigello-matrix';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { parseJsonFilter } from './use-table-filter/utils';

const MAX_PAGE_SIZE = 500 as const;
export async function fetchAllInstances<Instance extends BaseInstance>({
  modelName,
  filters,
  order,
  nested,
  slim,
}: {
  modelName: ModelName;
  filters: QueryParams['filters'];
  order: QueryParams['order'];
  nested?: (keyof Instance)[] | undefined;
  slim?: boolean;
}) {
  // check how many pages first
  const pageSizeData = await fetchGenericList<Instance>({
    modelName,
    queryParams: {
      filters: {
        ...(filters ?? {}),
      },
      page: 1,
      pageSize: 1,
      order,
      slim,
    },
    nested,
  });

  const totalSize = pageSizeData.meta.total_amount;

  const pageAmount = totalSize < 500 ? 1 : Math.ceil(totalSize / 500);

  const promises: Promise<{
    list: BaseInstance[];
    meta: Meta;
  }>[] = [];

  for (let i = 1; i <= pageAmount; i++) {
    promises.push(
      fetchGenericList<Instance>({
        modelName,
        queryParams: {
          filters: {
            ...(filters ?? {}),
          },
          page: i,
          pageSize: MAX_PAGE_SIZE,
          order,
          slim,
        },
        nested,
      })
    );
  }

  const result = await Promise.allSettled(promises);

  const succeededResults = (
    result as unknown as {
      value: ListResponse<Instance>;
      status: 'fulfilled' | 'rejected';
    }[]
  ).filter((r) => r.status === 'fulfilled');

  return {
    list: succeededResults.map((r) => r.value.list).flat(),
    meta: { ...pageSizeData.meta, page_amount: pageSizeData.meta.total_amount },
  };
}

export default function useFetchAllInstances<Instance extends BaseInstance>(
  options: Omit<
    UseQueryOptions<unknown, unknown, ListResponse<Instance>, Array<string>>,
    'queryKey' | 'queryFn'
  > & {
    modelName: ModelName;
    order: QueryParams['order'];
    filters: QueryParams['filters'];
    nested?: (keyof Instance)[] | undefined;
    slim?: boolean;
  }
) {
  const result = useQuery({
    queryFn: async () =>
      await fetchAllInstances({
        modelName: options.modelName,
        filters: options.filters,
        order: options.order,
        nested: options.nested,
        slim: options.slim,
      }),
    queryKey: [
      options.modelName,
      'allinstances',
      options.order ? options.order?.join(',') : 'noorder',
      options.filters ? parseJsonFilter(options.filters) : 'nofilters',
      options.slim ? 'slim' : '',
    ],
    ...options,
  });

  return result;
}
