import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { XMarkIcon } from '@heroicons/react/16/solid';
import { useQueryClient } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import { useState, type Dispatch, type SetStateAction } from 'react';
import { toast } from 'sonner';
import { useCreateDashboard } from '../hooks/use-create-dashboard';
import { DASHBOARD_QUERY_KEY } from '../hooks/use-get-dashboard';
import type { DashBoard, WorkEngagementDashboard } from '../types';

export function CreateDashboardModal({
  closeFunction,
  setDashboardKey,
  currentDashboard,
}: {
  closeFunction: () => void;
  currentDashboard: DashBoard | WorkEngagementDashboard | undefined;
  setDashboardKey: Dispatch<SetStateAction<string | undefined>>;
}) {
  const [checkIfUserWantsToCopy, setCheckIfUserWantsToCopy] = useState(true);
  const [userWantsToCopy, setUserWantsToCopy] = useState(false);
  const { mutateAsync, isPending: isCreatingDashboard } = useCreateDashboard();
  const [dashboardNameCreate, setDashboardNameCreate] = useState('');
  const [dashboardNameCopy, setDashboardNameCopy] = useState('');
  const queryClient = useQueryClient();
  const clearAndClose = () => {
    setDashboardNameCreate('');
    closeFunction();
  };
  const createDashboard = () => {
    toast.promise(mutateAsync({ name: dashboardNameCreate, widgets: [] }), {
      loading: 'Skapar...',
      success: (data) => {
        queryClient.invalidateQueries({
          queryKey: [DASHBOARD_QUERY_KEY],
        });
        clearAndClose();
        setDashboardKey(data.key);
        return 'Dashboard skapades';
      },
      error: 'Misslyckades med att skapa dashboard',
    });
  };

  const copyDashboard = () => {
    const clonedDashboard = cloneDeep(currentDashboard);
    if (!clonedDashboard) {
      toast.error('Misslyckades med att kopiera dashboard');
      return;
    }
    // @ts-expect-error need to remove when copying
    clonedDashboard.key = undefined;
    clonedDashboard.ranking = null;
    clonedDashboard.name = dashboardNameCopy;
    if ('work_engagement_id' in clonedDashboard) {
      // @ts-expect-error need to remove when copying
      clonedDashboard.work_engagement_id = undefined;
      // @ts-expect-error need to remove when copying
      clonedDashboard.created_by_user_id = undefined;
    }
    toast.promise(mutateAsync(clonedDashboard), {
      loading: 'Kopierar...',
      success: (data) => {
        queryClient.invalidateQueries({
          queryKey: [DASHBOARD_QUERY_KEY],
        });
        setDashboardKey(data.key);
        clearAndClose();
        return 'Dashboard kopierad';
      },
      error: 'Misslyckades med att kopiera dashboard',
    });
  };
  if (checkIfUserWantsToCopy && currentDashboard) {
    return (
      <Dialog defaultOpen onOpenChange={clearAndClose}>
        <DialogContent size='sm' className='p-0'>
          <DialogHeader className='flex flex-col items-start gap-2 border-b-0'>
            <div className='flex w-full items-center justify-between'>
              <DialogTitle>
                {userWantsToCopy
                  ? `Kopiera ${currentDashboard.name}`
                  : 'Skapa ny dashboard'}
              </DialogTitle>
              <Button
                variant='secondary'
                onClick={clearAndClose}
                size={'icon-sm'}
              >
                <XMarkIcon className='size-4 shrink-0' />
              </Button>
            </div>
            <DialogDescription>
              {userWantsToCopy
                ? `Välj ett namn på din nya dashboard och klicka sedan op på kopiera för att kopiera inställningarna från ${currentDashboard.name}.`
                : `Vill du kopiera inställningarna från ${currentDashboard.name}? Detta innebär att alla inställningar flyttas över till en ny
              dashboard.`}
            </DialogDescription>
          </DialogHeader>
          {!userWantsToCopy && (
            <DialogFooter className='border-t-0'>
              <Button
                variant='secondary'
                onClick={() => setCheckIfUserWantsToCopy(false)}
                size={'icon-sm'}
                type='button'
              >
                Skapa ny dashboard
              </Button>
              <Button type='submit' onClick={() => setUserWantsToCopy(true)}>
                Kopiera
              </Button>
            </DialogFooter>
          )}
          {userWantsToCopy && (
            <form onSubmit={(e) => e.preventDefault()}>
              <div className='grid gap-2 px-4'>
                <Label htmlFor='dashboard-name'>Namn på dashboard</Label>
                <Input
                  id='dashboard-name'
                  placeholder='Namn på dashboard'
                  defaultValue={dashboardNameCopy}
                  onChange={(e) => setDashboardNameCopy(e.target.value)}
                />
              </div>
              <DialogFooter className='border-t-0'>
                <Button
                  variant='secondary'
                  onClick={() => setUserWantsToCopy(false)}
                  size={'icon-sm'}
                  type='button'
                >
                  Tillbaka
                </Button>
                <Button
                  disabled={
                    isCreatingDashboard || dashboardNameCopy.trim().length === 0
                  }
                  type='submit'
                  onClick={copyDashboard}
                >
                  Kopiera
                </Button>
              </DialogFooter>
            </form>
          )}
        </DialogContent>
      </Dialog>
    );
  }
  return (
    <Dialog defaultOpen onOpenChange={clearAndClose}>
      <DialogContent size='sm' className='p-0'>
        <DialogHeader className='border-b-0'>
          <DialogTitle>Skapa ny dashboard</DialogTitle>
          <Button variant='secondary' onClick={clearAndClose} size={'icon-sm'}>
            <XMarkIcon className='size-4 shrink-0' />
          </Button>
          <DialogDescription className='sr-only'>
            Skapa ny dashboard
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className='grid gap-2 p-4'>
            <Label htmlFor='dashboard-name'>Namn på dashboard</Label>
            <Input
              id='dashboard-name'
              placeholder='Namn på dashboard'
              value={dashboardNameCreate}
              onChange={(e) => setDashboardNameCreate(e.target.value)}
            />
          </div>
          <DialogFooter className='border-t-0'>
            <Button
              disabled={
                isCreatingDashboard || dashboardNameCreate.trim().length === 0
              }
              type='submit'
              onClick={createDashboard}
            >
              Skapa
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
