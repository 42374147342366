 import type { WorkEngagement } from '@pigello/pigello-matrix';

import { WorkEngagementConfig } from "@pigello/pigello-matrix"



import { useGetInstance, useGetList } from '@/requests/hooks';
import type { useGetGenericInstanceProps, useGetGenericListProps, } from '@/requests/types';


export const useGetWorkEngagement = (
  options: useGetGenericInstanceProps<WorkEngagement>
) => {
  return useGetInstance<WorkEngagement>({
    ...options,
    config: WorkEngagementConfig,
    modelName: 'workengagement'
  });
};

export const useGetWorkEngagementList = (
  options: useGetGenericListProps<WorkEngagement>
) => {
  return useGetList<WorkEngagement>({
    ...options,
    config: WorkEngagementConfig,
    modelName: 'workengagement'
  });
};
