import { fetchApi } from '@/requests/api';
import { BASE_DASHBOARD_URL } from '@/requests/constants';
import { useMutation, type UseMutationOptions } from '@tanstack/react-query';
import type { DashBoard, WorkEngagementDashboard } from '../types';

export function useUpdateDashboard(
  options?: UseMutationOptions<
    DashBoard,
    Error,
    DashBoard | WorkEngagementDashboard
  >
) {
  return useMutation({
    ...options,
    mutationFn: async (dashboard) => {
      const res = await fetchApi({
        url: `${BASE_DASHBOARD_URL}/myworkday/dashboard/user`,
        method: 'PUT',
        body: JSON.stringify(dashboard),
      });
      if (!res.ok) throw new Error('Failed to update dashboard');
      const data = (await res.json()) as DashBoard;
      return data;
    },
  });
}
