import { fetchApi } from '@/requests/api';
import { BASE_DASHBOARD_URL } from '@/requests/constants';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { getCookies } from 'cookies-next';
import type { DashBoard } from '../types';

export const DASHBOARD_QUERY_KEY = 'dashboard';

export function useGetDashboards(
  options?: Omit<UseQueryOptions<DashBoard[]>, 'queryKey'>
) {
  const cookies = getCookies();
  return useQuery({
    ...options,
    queryKey: [DASHBOARD_QUERY_KEY],
    queryFn: async () => {
      const res = await fetchApi({
        url: `${BASE_DASHBOARD_URL}/myworkday/dashboard/user`,
        next: {
          revalidate: 0,
        },
      });

      const data = await res.json();
      return Array.isArray(data) ? data : [];
    },
    enabled: !!cookies?.token,
    staleTime: 60 * 5 * 1000,
    refetchOnWindowFocus: true,
  });
}
