import * as React from 'react';

import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/cn';
import { CheckIcon, FunnelIcon, XMarkIcon } from '@heroicons/react/16/solid';
interface FacetedFilterProps {
  selected: string[] | undefined;
  setSelected: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  options: {
    label: string;
    value: string;
    separator?: boolean;
    icon?: React.ComponentType<{ className?: string }>;
  }[];
  disabled?: boolean;
}

export function FacetedFilter({
  selected,
  setSelected,
  options,
  disabled,
}: FacetedFilterProps) {
  const selectedValues = new Set(selected);
  return (
    <Popover>
      <div>
        <div className='relative'>
          {selectedValues.size > 0 && (
            <div className='absolute -right-3 -top-3 z-10 rounded-full border bg-background p-0.5 text-xs font-semibold'>
              <div className='group flex size-4 items-center justify-center'>
                <Tooltip delayDuration={100}>
                  <TooltipTrigger asChild>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelected([]);
                      }}
                      className={cn(
                        'hidden',
                        selectedValues.size === 0 && 'group-hover:hidden',
                        selectedValues.size > 0 && 'group-hover:block'
                      )}
                    >
                      <XMarkIcon className='size-4' />
                    </button>
                  </TooltipTrigger>
                  <TooltipContent className='font-normal' align='end'>
                    Rensa
                  </TooltipContent>
                </Tooltip>
                <span
                  className={cn(
                    'block',
                    selectedValues.size === 0 && 'group-hover:block',
                    selectedValues.size > 0 && 'group-hover:hidden'
                  )}
                >
                  {selectedValues.size}
                </span>
              </div>
            </div>
          )}
        </div>
        <Tooltip>
          <TooltipTrigger asChild>
            <PopoverTrigger asChild disabled={disabled}>
              <Button
                disabled={disabled}
                variant='outline'
                size='icon'
                className={cn(
                  'border-dashed',
                  selectedValues.size > 0 && 'border-primary'
                )}
              >
                <FunnelIcon className='size-4 shrink-0' />
              </Button>
            </PopoverTrigger>
          </TooltipTrigger>
          <TooltipContent className='font-normal' align='end'>
            Filtrera
          </TooltipContent>
        </Tooltip>
      </div>
      <PopoverContent className='w-auto min-w-72 p-0' align='end' side='top'>
        <Command>
          <CommandInput placeholder={'Filtrera på...'} />
          <CommandList>
            <CommandEmpty>Inga resultat hittades.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                const isSelected = selectedValues.has(option.value);
                if (option.separator) {
                  return <CommandSeparator key={option.value} />;
                }
                return (
                  <CommandItem
                    value={option.value}
                    key={option.value}
                    keywords={[option.label, option.value]}
                    onSelect={() => {
                      if (isSelected) {
                        selectedValues.delete(option.value);
                      } else {
                        selectedValues.add(option.value);
                      }
                      const filterValues = Array.from(selectedValues);
                      setSelected(
                        filterValues.length ? filterValues : undefined
                      );
                    }}
                  >
                    <div
                      className={cn(
                        'mr-2 flex size-4 items-center justify-center rounded-sm border border-primary',
                        isSelected
                          ? 'bg-primary text-primary-foreground'
                          : 'opacity-50 [&_svg]:invisible'
                      )}
                    >
                      <CheckIcon className={cn('size-4')} />
                    </div>
                    {option.icon && (
                      <option.icon className='mr-2 size-4 text-muted-foreground' />
                    )}
                    <span>
                      {option.label === 'CT_VERBOSE_NAME'
                        ? option.value
                        : option.label}
                    </span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
          <>
            <CommandSeparator />
            <CommandGroup>
              <CommandItem
                disabled={selectedValues.size === 0}
                onSelect={() => setSelected(undefined)}
                className='justify-center text-center aria-disabled:opacity-50'
              >
                Rensa filter
              </CommandItem>
            </CommandGroup>
          </>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
